import IMask from 'imask';
import { Controller } from 'stimulus';

const PHONE_MASK = '(000) 000-0000';
const masks = {
  phone: PHONE_MASK,
};

export default class TextInputMaskController extends Controller {
  static targets = ['input'];
  connect() {
    const maskValue = mapMask(this.inputTarget.dataset.inputMaskValue);
    if (!maskValue) {
      return;
    }
    const maskOptions = {
      mask: maskValue,
    };
    IMask(this.inputTarget, maskOptions);
  }
}

const mapMask = (mask) => {
  return masks[mask] || mask;
};
