import { Controller } from 'stimulus';

export default class AnnualMileageController extends Controller {
  static targets = [
    'mileageDisplay',
    'primaryUsageCommute',
    'primaryUsageOccasional',
    'primaryUsageRideshare',
    'primaryUsageBusiness',
    'commuteDaysContainer',
    'commuteDaysCount',
    'commuteDistanceContainer',
    'commuteDistance',
    'rideshareContainer',
    'rideshare',
    'primaryUsageField',
    'annualizedMileageField',
    'rideshareField',
    'otherVehiclesHaveRideshareField',
    'customMileageField',
    'resetCustomMileage',
    'editCustomMileage',
    'usageError',
  ];

  #formatter = new Intl.NumberFormat('en-US', {});
  #totalMileage = 0;
  #calculatedMileage = 0;
  #primaryUsage;
  #customMileageSet = false;
  #commuteDays = '5';
  #commuteDistance = 12;
  #otherVehiclesHaveRideshare = false;
  #currentAnimationFrame = null;

  get #usageSelected() {
    return ['primaryUsageCommute',
      'primaryUsageOccasional',
      'primaryUsageRideshare',
      'primaryUsageBusiness'].some((usage) => this[usage + 'Target'].checked);
  }

  connect() {
    this.#otherVehiclesHaveRideshare = this.otherVehiclesHaveRideshareFieldTarget.value === 'true';

    this.#totalMileage = parseInt(this.annualizedMileageFieldTarget?.value, 10) || 0;
    this.#commuteDistance = parseInt(this.commuteDistanceTarget?.value, 10) || 0;

    if (this.#totalMileage !== 0) {
      this.#customMileageSet = true;
      this.editCustomMileageTarget.classList.add('active');
    }

    if (this.#usageSelected) {
      this.editCustomMileageTarget.classList.add('active');
    }

    this.update();
  }

  openDialog() {
    this.customMileageFieldTarget.value = this.#totalMileage;
  }

  setCustomMileage() {
    this.#totalMileage = parseInt(this.customMileageFieldTarget.value, 10) || 0;
    this.#customMileageSet = true;
    this.editCustomMileageTarget.classList.remove('active');
    this.resetCustomMileageTarget.classList.add('active');
    this.update();
  }

  resetCustomMileage() {
    this.#customMileageSet = false;
    this.editCustomMileageTarget.classList.add('active');
    this.resetCustomMileageTarget.classList.remove('active');
    this.update();
  }

  // Calculate dynamic duration based on start/end difference
  calculateAnimationDuration = (start, end) => {
    const MAX_DIFFERENCE = 60000;
    const MAX_DURATION = 2000; // 2 seconds
    const MIN_DURATION = 100; // 100 milliseconds

    const difference = Math.abs(end - start);
    const normalizedDiff = Math.sqrt(difference / MAX_DIFFERENCE);
    return Math.max(
      MIN_DURATION,
      normalizedDiff * MAX_DURATION,
    );
  };

  easeInOutCubic = (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;

  cancelCurrentAnimation() {
    if (this.#currentAnimationFrame) {
      window.cancelAnimationFrame(this.#currentAnimationFrame);
      this.#currentAnimationFrame = null;
    }
  }

  animateValue(start, end) {
    this.cancelCurrentAnimation();
    const duration = this.calculateAnimationDuration(start, end);

    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) { startTimestamp = timestamp; }
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const easeProgress = this.easeInOutCubic(progress);
      const currentValue = Math.floor(easeProgress * (end - start) + start);

      // Update display number
      this.mileageDisplayTarget.innerText = currentValue >= 99999
        ? this.#formatter.format(currentValue) + '+'
        : this.#formatter.format(currentValue);

      if (progress < 1) {
        this.#currentAnimationFrame = window.requestAnimationFrame(step);
      } else {
        this.#currentAnimationFrame = null;
      }
    };

    this.#currentAnimationFrame = window.requestAnimationFrame(step);
  }

  update() {
    const mileagePreAnimation = this.#totalMileage;
    let mileage = 0;
    let primaryUsage; // Implicitly prioritized by order in this method.

    if (this.#usageSelected) {
      this.usageErrorTarget.classList.add('hidden');
    }

    if (this.primaryUsageOccasionalTarget.checked) {
      primaryUsage = 'occasional';
      mileage += 12500;
    }

    if (this.primaryUsageCommuteTarget.checked) {
      if (this.commuteDaysCountTargets.every((el) => !el.checked)) {
        const commuteDaysCountTarget = this.commuteDaysCountTargets.find((el) => el.value === this.#commuteDays);
        if (commuteDaysCountTarget) { commuteDaysCountTarget.checked = true; }
      }

      if (this.commuteDistanceTarget.value === '') {
        this.commuteDistanceTarget.value = this.#commuteDistance;
      } else {
        this.#commuteDistance = parseInt(this.commuteDistanceTarget.value, 10) || 0;
      }

      primaryUsage = 'commute';
      this.#commuteDays = this.commuteDaysCountTargets.filter((el) => el.checked)[0]?.value;
      const days = this.#commuteDays ?? 0;
      const distance = this.commuteDistanceTarget.value ?? 0;
      mileage += days * distance * 2 * 52;
      this.commuteDistanceTarget.required = true;
      this.commuteDaysContainerTarget.classList.remove('hidden');
      this.commuteDistanceContainerTarget.classList.remove('hidden');
    } else {
      this.commuteDaysCountTargets.forEach((el) => el.checked = false);
      this.commuteDaysContainerTarget.classList.add('hidden');
      this.commuteDistanceTarget.value = '12';
      this.commuteDistanceTarget.required = false;
      this.commuteDistanceContainerTarget.classList.add('hidden');
    }

    if (this.primaryUsageRideshareTarget.checked) {
      primaryUsage = 'rideshare';
      const rideshare = this.rideshareTargets.filter((el) => el.checked)[0]?.value;
      if (rideshare === 'full_time') {
        mileage += 50000;
      } else if (rideshare === 'part_time') {
        mileage += 30000;
      }
      this.rideshareContainerTarget.classList.remove('hidden');
      this.rideshareFieldTarget.value = true;
    } else {
      this.rideshareContainerTarget.classList.add('hidden');
      // if there are other vehicles with rideshare, this #otherVehiclesHaveRideshare should be true
      // if there are NO other vehicles with rideshare, this #otherVehiclesHaveRideshare should be false
      // thus this should fill it with correct value
      this.rideshareFieldTarget.value = this.#otherVehiclesHaveRideshare;
    }

    if (this.primaryUsageBusinessTarget.checked) {
      primaryUsage = 'business';
      mileage += 60000;
    }

    this.#primaryUsage = primaryUsage;
    this.primaryUsageFieldTarget.value = this.#primaryUsage;
    this.#calculatedMileage = mileage;

    if (this.#customMileageSet) {
      if (this.#totalMileage === mileage || this.#totalMileage === 0) {
        this.#customMileageSet = false;
        this.editCustomMileageTarget.classList.add('active');
        this.resetCustomMileageTarget.classList.remove('active');
      }
    }

    if (!this.#customMileageSet) {
      this.#totalMileage = mileage;
    }

    if (this.#totalMileage > 99999) {
      this.#totalMileage = 99999;
    }

    this.mileageDisplayTarget.value = this.#totalMileage;

    if (this.#totalMileage > 0) {
      if (!this.#customMileageSet) {
        this.editCustomMileageTarget.classList.add('active');
      }
      this.mileageDisplayTarget.classList.remove('muted');
      this.animateValue(mileagePreAnimation, this.#totalMileage);
    } else {
      this.cancelCurrentAnimation();
      if (!this.#customMileageSet) {
        this.editCustomMileageTarget.classList.remove('active');
      }
      this.mileageDisplayTarget.classList.add('muted');
      this.mileageDisplayTarget.innerText = '——,———';
    }

    this.annualizedMileageFieldTarget.value = this.#totalMileage;
  }

  submit(e) {
    // AT LEAST ONE USAGE SELECTED
    if (!this.#usageSelected) {
      e.preventDefault();
      this.usageErrorTarget.classList.remove('hidden');
      return;
    }

    this.usageErrorTarget.classList.add('hidden');

    window.Analytics.trackClickEvent(window.currentStep, 'SUBMIT_ANNUAL_MILEAGE', {
      customMileage: this.#customMileageSet,
      primaryUsage: this.#primaryUsage,
      totalMileage: this.#totalMileage,
      calculatedMileage: this.#calculatedMileage,
      usages: {
        occasional: this.primaryUsageOccasionalTarget.checked,
        commute: this.primaryUsageCommuteTarget.checked,
        rideshare: this.primaryUsageRideshareTarget.checked,
        business: this.primaryUsageBusinessTarget.checked,
      },
    });
  }
}
