import { Controller } from 'stimulus';

export default class MailingAddressController extends Controller {
  static targets = [
    'form',
    'dialog',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'suggestedAddress1',
    'suggestedAddress2',
    'suggestedCity',
    'suggestedState',
    'suggestedZip',
    'incorrectUnitAddress2',
    'undeliverableAccepted',
  ];

  connect() {
    if (this.hasDialogTarget) {
      this.dialogTarget.showModal();
    }
  }

  submitSuggestedAddress(e) {
    e.preventDefault();

    this.address1Target.value = this.suggestedAddress1Target.value;
    this.address2Target.value = this.suggestedAddress2Target.value;
    this.cityTarget.value = this.suggestedCityTarget.value;
    this.stateTarget.value = this.suggestedStateTarget.value;
    this.zipTarget.value = this.suggestedZipTarget.value;

    this.formTarget.submit();
  }

  submitIncorrectUnit(e) {
    e.preventDefault();

    this.address2Target.value = this.incorrectUnitAddress2Target.value;
    this.formTarget.submit();
  }

  submitUndeliverable(e) {
    e.preventDefault();

    this.undeliverableAcceptedTarget.value = 'true';
    this.formTarget.submit();
  }

  /**
   * Handles the behavior when the user clicks 'Edit Address' in a dialog.
   * Prevents the default form submission behavior and closes the dialog.
   * If a redirect slug is provided, navigates to the specified slug using Turbo.
   * This was added to make the modals reusable across different pages.
   */
  editAddress(e) {
    e.preventDefault();

    const redirectSlug = e.target.dataset?.redirectSlug;

    this.dialogTarget.close();
    if (redirectSlug && redirectSlug.trim().length > 0) {
      window.Turbo.visit(redirectSlug);
    }
  }
}
