import { Controller } from 'stimulus';

export default class ElementVisibilityController extends Controller {
  static targets = ['hideable'];

  toggle() {
    this.hideableTargets.forEach((t) => {
      t.hidden = !t.hidden;
    });
  }

  hide() {
    this.hideableTargets.forEach((t) => {
      t.hidden = true;
    });
  }

  show() {
    this.hideableTargets.forEach((t) => {
      t.hidden = false;
    });
  }
}
