import { Controller } from 'stimulus';

export default class SwitchTextToggleController extends Controller {
  static targets = ['switch', 'supportingText', 'inverseSwitch', 'headline', 'startIcon'];

  toggle() {
    // checked allows us to use this with a regular checkbox as well as a switch
    if (this.switchTarget.selected ?? this.switchTarget.checked) {
      this.supportingTextTarget.textContent = this.switchTarget.displayValue || this.switchTarget.value;

      this.headlineTarget?.classList?.remove('muted');
      this.startIconTarget?.classList?.remove('muted');

      if (this.startIconTarget) {
        this.startIconTarget.textContent = this.startIconTarget?.dataset?.startIcon;
      }
    } else {
      this.supportingTextTarget.textContent = this.inverseSwitchTarget.dataset.displayValue || this.inverseSwitchTarget.value;

      this.headlineTarget?.classList?.add('muted');
      this.startIconTarget?.classList?.add('muted');

      if (this.startIconTarget?.dataset?.startIconOff) {
        this.startIconTarget.textContent = this.startIconTarget?.dataset?.startIconOff;
      }
    }
  }
}
