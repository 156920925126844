import { Controller } from 'stimulus';

export default class InverseSelectionController extends Controller {
  static targets = ['inverseInput', 'switch'];

  toggle() {
    // checked allows us to use this with a regular checkbox as well as a switch
    this.inverseInputTarget.disabled = this.switchTarget.selected ?? this.switchTarget.checked;
  }
}
