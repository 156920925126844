import { Controller } from 'stimulus';

export default class NoVehiclesFoundController extends Controller {
  static targets = ['dialog'];

  connect() {
    this.dialogTarget.close();
  }

  showModal(e) {
    e.preventDefault();
    this.dialogTarget.showModal();
  }
}
