import { Controller } from 'stimulus';

export default class OsanoDrawerController extends Controller {
  openDrawer() {
    window.Osano.cm.showDrawer();
  }

  handleKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.openDrawer();
    }
  }
}
