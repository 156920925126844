import { Controller } from 'stimulus';

export default class DobFieldCombinerController extends Controller {
  static targets = ['month', 'day', 'year', 'dob'];

  connect() {
    if (this.dobTarget.value) {
      const dob = this.dobTarget.value.split('-');
      this.yearTarget.value = dob[0];
      this.monthTarget.value = dob[1];
      this.dayTarget.value = dob[2];
    }
  }

  combineDob() {
    // if all three fields are valid, combine them into the hidden dob field
    if (this.monthTarget.validity.valid && this.dayTarget.validity.valid && this.yearTarget.validity.valid) {
      this.dobTarget.value = `${this.yearTarget.value}-${this.monthTarget.value}-${this.dayTarget.value}`;
    }
  }
}
