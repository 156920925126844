import { Controller } from 'stimulus';

export default class ObfuscatableInputs extends Controller {
  static targets = ['input'];

  connect() {
    this.inputTargets.forEach((inputTarget) => {
      inputTarget.dataset.originalType ??= inputTarget.type;
      if (this._is_obfuscated(inputTarget)) {
        inputTarget.type = 'text';
      }
    });
  }

  allowObfuscation(event) {
    this.inputTargets.forEach((inputTarget) => {
      if (inputTarget.type !== inputTarget.dataset.originalType) {
        inputTarget.type = inputTarget.dataset.originalType;
      }

      if (this._is_obfuscated(inputTarget)) {
        inputTarget.value = event.target === inputTarget ? event.data ?? '' : '';
      }
    });
  }

  _value_for(target) {
    return target.value || target.getAttribute('value');
  }

  _is_obfuscated(inputTarget) {
    return /\*\**/.test(this._value_for(inputTarget));
  }
}
