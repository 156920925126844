import { Controller } from 'stimulus';

export default class SelectComponentController extends Controller {
  static targets = ['select'];

  connect() {
    this.selectTarget.updateComplete.then(() => {
      this.selectTarget.selectedIndex = this.element.dataset.selectedIndexValue;
    });
  }
}
