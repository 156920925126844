import { Controller } from 'stimulus';

export default class DialogComponentController extends Controller {
  static targets = ['dialog'];

  openDialog() {
    this.#trackEvent('OPEN');
    this.dialogTarget.showModal();
  }

  closeDialog() {
    this.#trackEvent('CLOSE');
    this.dialogTarget.close();
  }

  closeOnOutsideClick(event) {
    if (event.target === this.dialogTarget) {
      this.closeDialog();
    }
  }

  #trackEvent(eventType) {
    let event = this.data.get('dialogName') ?? 'MODAL';

    if (eventType) {
      event = event.concat('_', eventType);
    }

    window.Analytics.trackEvent(window.currentStep, event);
  }
}
