import { Controller } from 'stimulus';

export default class AutocompleteAddressController extends Controller {
  static targets = ['address', 'address1', 'address2', 'city', 'state', 'zip'];

  connect() {
    const addressString = [this.address1Target.value, this.cityTarget.value, this.stateTarget.value].filter((field) => !!field).join(', ');
    this.addressTarget.value = addressString;
  }

  initGoogle() {
    let addressInput = this.addressTarget;
    if (this.addressTarget.nodeName.startsWith('MD-')) { //Material components
      addressInput = this.addressTarget.shadowRoot.querySelector('input');
    }

    if (window.google?.maps?.places?.Autocomplete) {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(addressInput, {
        componentRestrictions: { country: ['us', 'ca']},
        fields: ['address_components', 'geometry'],
        types: ['address'],

      });
      this.autocomplete.addListener('place_changed', this.onPlaceChanged.bind(this));
    }
  }

  onPlaceChanged() {
    const place = this.autocomplete.getPlace();
    const address = this.getAddressComponent(place);

    if (address.street_number === undefined) {
      this.address1Target.value = address.route;
    } else {
      this.address1Target.value = address.street_number + ' ' + address.route;
    }
    this.cityTarget.value = address.locality;
    this.stateTarget.value = address.administrative_area_level_1;
    this.zipTarget.value = address.postal_code;
  }

  getAddressComponent(place) {
    return place.address_components.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.types[0]]: cur.short_name,
      };
    }, {});
  }
}
