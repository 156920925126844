import { Controller } from 'stimulus';

export default class PrefillDriversController extends Controller {
  static targets = ['switch', 'dialog'];

  connect() {
    this.dialogTarget.close();
  }

  validateSwitches(e) {
    const unSelectedDriver = this.switchTargets.some(({ selected }) => !selected);

    if (unSelectedDriver) {
      if (this.dialogTarget.open) {
        return;
      }

      e.preventDefault();
      this.dialogTarget.showModal();
      this.renderDriversInModal();
    }
  }

  renderDriversInModal() {
    this.switchTargets.reduce((workingTargets, { selected }, index) => {
      const element = document.querySelector(`#hidable-${index}`);
      element.style.display = selected ? 'none' : 'block';

      if (!selected) {
        workingTargets.push(element);
      }

      return workingTargets;
    }, []);
  }
}
