import { Controller } from 'stimulus';

export default class SnapScrollController extends Controller {
  static targets = ['scrollContainer', 'scrollTarget', 'indicator', 'leftButton', 'rightButton', 'leftGradient', 'rightGradient'];

  #index = 0;

  connect() {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (!entry.isIntersecting) { return; }
        if (entry.target !== this.scrollTargetTargets[this.#index]) {
          this.#index = this.scrollTargetTargets.indexOf(entry.target);
        }
      }

      this.#updateClasses();
    }, {
      root: this.scrollContainerTarget,
      rootMargin: '0px',
      threshold: 0.98,
    });

    this.scrollTargetTargets.forEach((target) => observer.observe(target));
    this.#updateClasses();
  }

  scrollLeft() {
    if (this.#index > 0) {
      this.#index--;
      this.scrollTargetTargets[this.#index].scrollIntoView({ behavior: 'smooth' });
    }

    this.#updateClasses();
  }

  scrollRight() {
    if (this.#index + 1 < this.scrollTargetTargets.length) {
      this.#index++;
      this.scrollTargetTargets[this.#index].scrollIntoView({ behavior: 'smooth' });
    }

    this.#updateClasses();
  }

  #updateClasses() {
    this.indicatorTargets.forEach((indicator, index) => {
      if (index === this.#index) {
        indicator.classList.add('primary');
        indicator.classList.remove('muted');
      } else {
        indicator.classList.remove('primary');
        indicator.classList.add('muted');
      }
    });

    if (this.#index === 0) {
      this.leftButtonTarget.hidden = true;
      this.leftGradientTarget.hidden = true;
    } else {
      this.leftButtonTarget.hidden = false;
      this.leftGradientTarget.hidden = false;
    }

    if (this.#index === this.scrollTargetTargets.length - 1) {
      this.rightButtonTarget.hidden = true;
      this.rightGradientTarget.hidden = true;
    } else {
      this.rightButtonTarget.hidden = false;
      this.rightGradientTarget.hidden = false;
    }
  }
}
