import { Controller } from 'stimulus';
const monthDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export default class DobValidationController extends Controller {
  static targets = ['month', 'day', 'year', 'dob', 'error'];

  applyDayMask(e) {
    const maxDaysInMonth = this.#getMaxDaysInMonth(parseInt(this.monthTarget.value), parseInt(this.yearTarget.value));
    e.target.value = this.#noAlphanumerics(e.target.value); // don't allow non-numeric characters
    e.target.value = this.#zeroPadDigits(e.target.value, 3); // first digits greater than 1 are prefixed with a 0
    e.target.value = this.#limitXDigitsValue(e.target.value, 2, 1, maxDaysInMonth); // limit the day to 31
    e.target.value = this.#limitLength(e.target.value, 2); // limit the length to 2
    this.#focusNextField(e, this.yearTarget, 2); //jump to the year input when field is full
  }

  applyMonthMask(e) {
    e.target.value = this.#noAlphanumerics(e.target.value); // don't allow non-numeric characters
    e.target.value = this.#zeroPadDigits(e.target.value, 1); // first digits greater than 1 are prefixed with a 0
    e.target.value = this.#limitXDigitsValue(e.target.value, 2, 1, 12); // limit to 12 months
    e.target.value = this.#limitLength(e.target.value, 2); // limit the length to 2
    this.#focusNextField(e, this.dayTarget, 2); //jump to the day input when field is full
    this.#fixupDaysInMonth(); // fix the day field if the month changes
  }

  applyYearMask(e) {
    const yearLowerBound = new Date(this.dobTarget.min).getUTCFullYear();
    const yearUpperBound = new Date(this.dobTarget.max).getUTCFullYear();
    e.target.value = this.#noAlphanumerics(e.target.value); // don't allow non-numeric characters
    e.target.value = this.#limitXDigitsValue(e.target.value, 1, this.#getNDigits(yearLowerBound, 1), this.#getNDigits(yearUpperBound, 1)); // limit the first digit
    e.target.value = this.#limitXDigitsValue(e.target.value, 2, this.#getNDigits(yearLowerBound, 2), this.#getNDigits(yearUpperBound, 2)); // limit the second digit
    e.target.value = this.#limitXDigitsValue(e.target.value, 3, this.#getNDigits(yearLowerBound, 3), this.#getNDigits(yearUpperBound, 3)); // limit the third digit
    e.target.value = this.#limitXDigitsValue(e.target.value, 4, yearLowerBound, yearUpperBound); // limit the last digit
    e.target.value = this.#limitLength(e.target.value, 4); // limit the length to 4
    this.#fixupDaysInMonth(); // fix the day field if the year changes (e.g. leap year to non leap year)
  }

  validateDate(e) {
    const lowerBoundDate = new Date(Date.parse(this.dobTarget.min));
    const upperBoundDate = new Date(Date.parse(this.dobTarget.max));

    if (/\*\**/.test(this.dobTarget.value)) { // handle obfuscated inputs
      return;
    }
    const dobDate = new Date(Date.parse(this.dobTarget.value));
    if (isNaN(dobDate.getTime())) {
      e.preventDefault();
      this.errorTarget.innerHTML = 'Please enter a valid date';
      return;
    } else if (upperBoundDate < dobDate || lowerBoundDate > dobDate) {
      e.preventDefault();
      const options = {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      this.errorTarget.innerHTML = `Date must be between ${formatter.format(lowerBoundDate)} and ${formatter.format(upperBoundDate)}`;
      return;
    }

    this.errorTarget.innerHTML = '';
  }

  zeroPadOnBlur() {
    this.monthTarget.value = this.#zeroPadDigits(this.monthTarget.value, 0);
    this.dayTarget.value = this.#zeroPadDigits(this.dayTarget.value, 0);
  }

  // private methods
  #noAlphanumerics(val) {
    return val.replace(/[^0-9]/g, '');
  }

  #zeroPadDigits(val, replaceDigitsGreaterThan = 1) {
    return val.length === 1 && parseInt(val) > replaceDigitsGreaterThan ? `0${val}` : val;
  }

  #limitXDigitsValue(val, xDigits, lowerBound, upperBound) {
    return val.length === xDigits && (parseInt(val) < lowerBound || parseInt(val) > upperBound) ? val.replace(/.$/, '') : val;
  }

  #limitLength(val, maxLength) {
    return val.length > maxLength ? val.replace(/.$/, '') : val;
  }

  #focusNextField(e, nextTarget, maxLength) {
    if (e.target.value.length === maxLength) {
      nextTarget.focus(); // jump to the day input
    }
  }

  #getNDigits(val, n) {
    return parseInt(val.toString().slice(0, n));
  }

  #isLeapYear(year) {
    if (!year) {
      return false;
    }
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }

  #getMaxDaysInMonth(month, year) {
    if (!month) {
      return 31;
    }
    if (month === 2) { // handle February specifically
      return month === 2 && this.#isLeapYear(year) ? 29 : monthDays[month - 1] - 1;
    }
    return monthDays[month - 1];
  }

  #fixupDaysInMonth() {
    const maxDaysInMonth = this.#getMaxDaysInMonth(parseInt(this.monthTarget.value), parseInt(this.yearTarget.value));
    if (parseInt(this.dayTarget.value) > maxDaysInMonth) {
      this.dayTarget.value = maxDaysInMonth;
    }
  }
}
