import { Controller } from 'stimulus';

export default class PrefillVehiclesController extends Controller {
  static targets = ['switch', 'dialog'];

  connect() {
    this.dialogTarget.close();
  }

  validateSwitches(e) {
    let oneSelected = false;
    this.switchTargets.some((switchElement) => {
      if (switchElement.selected) {
        oneSelected = true;
        return true;
      }
      return false;
    });

    if (!oneSelected) {
      e.preventDefault();
      this.dialogTarget.showModal();
    }
  }
}
