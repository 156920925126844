import { Controller } from 'stimulus';

export default class PrefillLoaderController extends Controller {
  static values = {
    url: String,
    refreshInterval: Number,
  };

  async getUpdate() {
    const request = fetch(this.data.get('url'),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    const response = await request;
    return {
      status: response.status,
      url: response.url,
    };
  }

  async load() {
    const result = await this.getUpdate();
    if (result.status === 200 && result.url !== this.data.get('url')) {
      window.Turbo.visit(result.url, { action: 'replace' });
    } else {
      this.refresh();
    }
  }

  connect() {
    this.retryCount = this.data.get('numberOfRetriesValue') ?? 120;

    if (this.data.get('refreshIntervalValue')) {
      this.pollCount = 0;
      this.refresh();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  refresh() {
    if (this.pollCount > this.retryCount) {
      this.stopRefreshing();
    }

    this.pollCount += 1;
    this.refreshTimer = setTimeout(() => this.load(), this.data.get('refreshIntervalValue'));
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  }
}
